'use strict';

class StatCardController {

  constructor($scope) {
    this.$scope = $scope;
    var ctrl = this;
    $scope.$watch('elements', function() {
      ctrl.sizeElements();
    });
  }

  sizeElements() {
    var biggestValue = 5;
    if (this.$scope.elements.length > 10) {
      this.$scope.elements.splice(0,this.$scope.elements.length-10);
    }
    for (var i=0 ; i<this.$scope.elements.length ; i++) {
      biggestValue = this.$scope.elements[i].value>biggestValue ? this.$scope.elements[i].value : biggestValue;
    }
    for (i=0 ; i<this.$scope.elements.length ; i++) {
      this.$scope.elements[i].percentage = 100*this.$scope.elements[i].value/biggestValue;
      if (this.$scope.elements[i].percentage===0) {
        this.$scope.elements[i].percentage=1;
      }
    }
  }

}

angular.module('cpformplastApp')
  .controller('StatCardController', StatCardController);
